<template>
  <div>
    <base-period-select
      v-model="searchDates"
      class="justify-start ml-2"
      :bootstrap="BOOTSTRAP"
    />
    <base-loading v-if="!ready" />
    <v-container
      v-else
      fluid
      style="margin-bottom: 60px;"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Solicitação de Cadastro"
            :value="String(dashboard.cadastros) || ''"
            icon="mdi-account-check"
            color="#9B3491"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            sub-text="Cadastros Pendentes até o momento"
            elevation="3"
            color-background="linear-gradient(270deg, rgba(155, 52, 145, 0.50) 2.79%, #9B3491 96.1%)"
            :action="redirecionamento('cadastros/?status=Aguardando Análise,Aguardando Aprovação')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Cotações Aguardando"
            :value="String(dashboard.cotacao.aguardando_cotacao) || ''"
            icon="mdi-file-clock-outline"
            color="#1C4EFF"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #1C4EFF 2.79%, #1F3995 96.1%)"
            :action="redirecionamento('cdv/cotacoes/?status=Aguardando Cotação')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Cotações Em Análise"
            :value="String(dashboard.cotacao.analise) || ''"
            icon="mdi-book-clock-outline"
            color="#D39942"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #D39942 2.79%, #855C20 96.1%)"
            :action="redirecionamento('cdv/cotacoes/?status=Em Análise')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Cotações Aguardando Aceite"
            :value="String(dashboard.cotacao.aguardando_aceite) || ''"
            icon="mdi-file-star-outline"
            color="#2DADED"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #2DADED 2.79%, #225A76 96.1%)"
            :action="redirecionamento('cdv/cotacoes/?status=Aguardando Aceite Produtor')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Cotações Contra Proposta"
            :value="String(dashboard.cotacao.contraproposta) || ''"
            icon="mdi-clipboard-check-outline"
            color="#184294"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #183B94 2.79%, #0E1B3D 96.1%)"
            :action="redirecionamento('cdv/cotacoes/?status=Contraproposta Produtor')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Cotações Cancelamento Solicitado"
            :value="String(dashboard.cotacao.solicitado_cancelamento) || ''"
            icon="mdi-file-remove-outline"
            color="#D35D42"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            color-background="linear-gradient(270deg, #D35D42 2.79%, #7A301F 96.1%)"
            :action="redirecionamento('cdv/cotacoes/?status=Cancelamento Solicitado')"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Pedidos Aguardando Aprovação"
            :value="String(dashboard.pedidos.aguardando_aprovacao) || ''"
            icon="mdi-file-clock-outline"
            color="#1C4EFF"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #1C4EFF 2.79%, #1F3995 96.1%)"
            :action="redirecionamento('cdv/pedidos/?status=Aguardando Aprovação')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Pedidos Em Análise"
            :value="String(dashboard.pedidos.analise) || ''"
            icon="mdi-book-clock-outline"
            color="#D39942"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #D39942 2.79%, #855C20 96.1%)"
            :action="redirecionamento('cdv/pedidos/?status=Em Análise')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Pedidos Aguardando Aceite"
            :value="String(dashboard.pedidos.aguardando_aceite) || ''"
            icon="mdi-file-star-outline"
            color="#2DADED"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #0AA5F2 2.79%, #1983B8 96.1%)"
            :action="redirecionamento('cdv/pedidos/?status=Aguardando Aceite Produtor')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Pedidos Aprovados"
            :value="String(dashboard.pedidos.aprovados) || ''"
            icon="mdi-file-check-outline"
            color="#188594"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #188594 2.79%, #144148 96.1%)"
            :action="redirecionamento('cdv/pedidos/?status=Aprovado')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Pedidos Cancelamento Solicitado"
            :value="String(dashboard.pedidos.solicitado_cancelamento) || ''"
            icon="mdi-file-remove-outline"
            color="#D35D42"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #D35D42 2.79%, #7A301F 96.1%)"
            :action="redirecionamento('cdv/pedidos/?status=Cancelamento Solicitado')"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <material-stats-card
            title="Pedidos Faturados"
            :value="String(dashboard.pedidos.faturados) || ''"
            icon="mdi-clipboard-check-outline"
            color="#183B94"
            sub-icon="mdi-clock-outline"
            sub-icon-color="#FFFFFF"
            :sub-text="labelGrafico"
            elevation="3"
            color-background="linear-gradient(270deg, #183B94 2.79%, #0E1B3D 96.1%)"
            :action="redirecionamento('cdv/pedidos/?status=Faturado')"
          />
        </v-col>
      </v-row>
      <v-row
        class="pt-0 pb-0 py-0"
      >
        <v-col
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="graficoLine"
        >
          <material-chart-card
            :data="dashboard.grafico_pedido.data || []"
            :options="optionsped()"
            type="Bar"
            color="primary"
            title="Pedidos no Período"
            icon="mdi-chart-line"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="3"
            class="grafico-barra"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          lg="12"
          xl="12"
          class="graficoLine"
        >
          <material-chart-card
            :data="dashboard.grafico_cotacao.data || []"
            :options="optionscot()"
            type="Bar"
            color="primary"
            title="Cotações no Período"
            icon="mdi-chart-line"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="3"
            class="grafico-barra"
          />
        </v-col>
      </v-row>
      <v-row
        class="pt-0 pb-0 py-0"
      >
        <v-col
          cols="12"
          lg="12"
          xl="6"
          class="graficoPieTec"
        >
          <material-chart-card
            :data="dashboard.grafico_tecnico.data || []"
            :options="optionsTec"
            type="Pie"
            color="primary"
            title="Vendas por Técnico (R$)"
            icon="mdi-chart-donut"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          xl="6"
          class="graficoPieCat"
        >
          <material-chart-card
            :data="dashboard.grafico_categoria.data || []"
            :options="optionsCat"
            type="Pie"
            color="primary"
            title="Vendas por Categoria de Produto (R$)"
            icon="mdi-chart-donut"
            sub-icon-left="mdi-clock-outline"
            sub-icon-color-left="info"
            :sub-text-left="labelGrafico"
            elevation="3"
          />
        </v-col>
        <v-col
          cols="12"
          lg="12"
          xl="12"
        >
          <material-card
            color="primary"
            title="Positivação de produtores"
            icon="mdi-chart-donut"
            elevation="3"
            style="min-width: '100%'; min-height: 435px;"
          >
            <v-row
              class="pt-11"
              justify="center"
              style="overflow-y: hidden;"
            >
              <vue-speedometer
                :max-segment-labels="5"
                :segments="5"
                :min-value="0"
                value-format="d"
                :max-value="dashboard.grafico_produtor.limite || ''"
                :value="dashboard.grafico_produtor.atual || 0"
                current-value-text="Produtores Positivados: ${value}"
                :width="455"
                needle-color="#000080"
                needle-transition="easeElastic"
                urrent-value-placeholder-style="#{value}"
                style="min-width: 480px;"
              />
            </v-row>
            <template
              v-slot:actions
            >
              <v-icon
                color="info"
                size="16"
                class="ml-1 mr-1"
              >
                mdi-clock-outline
              </v-icon>
              <span
                class="caption grey--text font-weight-light"
              >{{ labelGrafico }}</span>
            </template>
          </material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import VueSpeedometer from 'vue-speedometer'
  import { mapGetters, mapState, mapActions } from 'vuex'
  import MaterialStatsCard from '../../components/base/MaterialStatsCard'
  import MaterialCard from '../../components/base/MaterialCard'
  import MaterialChartCard from '../../components/base/MaterialChartCard'
  import { BOOTSTRAP, LOAD_BATCH } from '@/store/modules/dashboard'
  import Tooltips from 'chartist-plugin-tooltips-updated'
  import Legend from 'chartist-plugin-legend'
  import { currencyFormatter } from '../../utils/formatter'

  export default {
    name: 'DashboardAdmin',
    components: {
      MaterialChartCard,
      MaterialStatsCard,
      MaterialCard,
      VueSpeedometer,
    },
    data () {
      return {
        styles: {
          width: '500px',
          height: '300px',
          background: '#2a2744',
        },
        optionsCat: {
          donut: false,
          width: '70%',
          donutWidth: '30%',
          donutSolid: true,
          labelDirection: 'explode',
          showLabel: true,
          height: 290,
          labelOffset: -20,
          plugins: [
            Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + ' R$ ' + currencyFormatter(value) },
            }),
          ],
          labelInterpolationFnc: this.calcCategoria,
        },
        optionsTec: {
          donut: false,
          width: '70%',
          donutWidth: '30%',
          donutSolid: true,
          labelDirection: 'explode',
          showLabel: true,
          labelOffset: -20,
          height: 290,
          plugins: [
            Legend(),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + ' R$ ' + currencyFormatter(value) },
            }),
          ],
          labelInterpolationFnc: this.calcTecnico,
        },

        labelGraficoPedido: [
          'Aceite Produtor',
          'Aprovação',
          'Em Análise',
          'Aprovado',
          'Cancelamento Solicitado',
          'Cancelado',
          'Faturado',
          'Entregue ao Técnico',
          'Entregue à Transportadora',
          'Entregue ao Produtor',
        ],

        labelGraficoCotacao: [
          'Cotação',
          'Aceite Produtor',
          'Contraproposta Produtor',
          'Em Análise',
          'Aprovado',
          'Cancelamento Solicitado',
          'Cancelado',
        ],

        searchDates: {
          name: 'admin',
          label: 0,
          selected: 'Trimestre',
          from: null,
          to: null,
        },
      }
    },
    computed: {
      ...mapState({ user: state => state.user }),
      ...mapState('dashboard', ['ready', 'dashboard']),
      ...mapGetters(['hasPermission']),

      labelGrafico () {
        switch (this.searchDates.label) {
          case 0:
            return 'Últimos ' + (this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'days') + 1) + ' dias'
          case 1:
            return 'Últimos ' + (this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'days') + 1) + ' dias'
          case 2:
            return 'Últimos ' + (this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'days') + 1) + ' dias'
          case 3:
            return 'Últimos ' + (this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'days') + 1) + ' dias'
          case 4:
            return 'Últimos ' + (this.moment(this.searchDates.to).diff(this.moment(this.searchDates.from), 'days') + 1) + ' dias'
          case 6:
            return `Período de ${this.moment(this.searchDates.from).format('DD/MM/YYYY')} a ${this.moment(this.searchDates.to).format('DD/MM/YYYY')}`
          default:
            return ''
        }
      },
    },
    methods: {
      ...mapActions('dashboard', [BOOTSTRAP, LOAD_BATCH]),
      calcCategoria (value, idx) {
        return Math.round(this.dashboard.grafico_categoria?.data.series[idx].value / this.dashboard.grafico_categoria?.data.series.reduce(function (a, b) { return a + b.value }, 0) * 100) + ' %'
      },
      calcTecnico (value, idx) {
        return Math.round(this.dashboard.grafico_tecnico?.data.series[idx].value / this.dashboard.grafico_tecnico?.data.series.reduce(function (a, b) { return a + b.value }, 0) * 100) + ' %'
      },
      redirecionamento (path) {
        return () => {
          this.$router.push({ path }).catch(() => {})
        }
      },
      pontos (options) {
        var defaultOptions = {
          labelClass: 'ct-label',
          labelOffset: {
            x: 0,
            y: -10,
          },
          textAnchor: 'middle',
          align: 'center',
          labelInterpolationFnc: this.$chartist.noop,
        }

        var labelPositionCalculation = {
          point: function (data) {
            return {
              x: data.x,
              y: data.y,
            }
          },
          bar: {
            left: function (data) {
              return {
                x: data.x1,
                y: data.y1,
              }
            },
            top: function (data) {
              return {
                x: data.x1,
                y: data.y2,
              }
            },
            center: function (data) {
              return {
                x: data.x1 + (data.x2 - data.x1) / 2,
                y: data.y1,
              }
            },
            right: function (data) {
              return {
                x: data.x2,
                y: data.y1,
              }
            },
          },
        }

        options = this.$chartist.extend({}, defaultOptions, options)

        function addLabel (position, data) {
          var value = data.value.x !== undefined && data.value.y
            ? (data.value.x + ', ' + data.value.y)
            : data.value.y || data.value.x

          value = value === undefined ? 0 : value
          data.group.elem('text', {
            x: position.x + options.labelOffset.x,
            y: position.y + options.labelOffset.y,
            style: 'text-anchor: ' + options.textAnchor,
          }, options.labelClass).text(options.labelInterpolationFnc(value))
        }

        return function ctPointLabels (chart) {
          chart.on('draw', function (data) {
            var positonCalculator = (labelPositionCalculation[data.type] && labelPositionCalculation[data.type][options.align]) || labelPositionCalculation[data.type]
            if (positonCalculator) {
              addLabel(positonCalculator(data), data)
            }
          })
        }
      },
      optionsped () {
        return {
          low: 0,
          showArea: false,
          seriesBarDistance: 15,
          axisX: {
            offset: 20,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            this.$chartist.plugins.legend({ legendNames: this.labelGraficoPedido }),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + Math.round(value, 0) },
            }),
          ],
          chartPadding: {
            top: 50,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },
      optionscot () {
        return {
          low: 0,
          showArea: false,
          seriesBarDistance: 15,
          axisX: {
            offset: 20,
          },
          axisY: {
            onlyInteger: true,
            offset: 60,
          },
          lineSmooth: this.$chartist.Interpolation.monotoneCubic({
            tension: 0.1,
          }),
          height: 290,
          plugins: [
            this.$chartist.plugins.legend({ legendNames: this.labelGraficoCotacao }),
            Tooltips({
              appendToBody: false,
              tooltipFnc: function (meta, value) { return meta + '<br />' + Math.round(value, 0) },
            }),
          ],
          chartPadding: {
            top: 50,
            right: 0,
            bottom: 0,
            left: 0,
          },
        }
      },
    },
  }
</script>

<style lang="scss">

.graficoLine .ct-square {
  height: 290px;
}

.graficoPieTec .ct-square {
  height: 290px;
}

.graficoPieCat .ct-square {
  height: 290px;
}

.graficoLine .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoPieTec .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoPieCat .v-card--material-chart .v-card--material__heading {
  max-height: 315px;
}

.graficoLine .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

.graficoPieTec .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

.graficoPieCat .v-card--material-chart .theme--dark.v-card {
  background-color: white !important
}

.graficoPieTec .ct-legend {
  z-index: 10;
  list-style: none;
  text-align: left;

  li {
    position: relative;
    padding-left: 23px;
    margin-right: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    display: block;
  }
}

.graficoPieCat .ct-legend {
  z-index: 10;
  list-style: none;
  text-align: left;

  li {
    position: relative;
    padding-left: 23px;
    margin-right: 10px;
    margin-bottom: 3px;
    cursor: pointer;
    display: block;
  }
}

.grafico-barra .ct-bar {
  stroke-width: 18px !important;
}

</style>
